<script>
import GeneralCheckbox from '../GeneralCheckbox';

export default {
    components: {
        GeneralCheckbox
    },
    props: {
        label: {
            type: String,
            required: true,
        },
        modelValue: {
            type: Boolean,
            default: false,
        },
    },
    emits: [
        'update:model-value'
    ]
}
</script>

<template>
    <div class="boolean-field">
        <general-checkbox 
            :model-value="modelValue"
            @update:model-value="$emit('update:model-value', $event)"
            :label="label" />
    </div>
</template>