<script>
import StarsRating from '@/components/general/StarsRating.vue';
import TextArea from '@/components/general/TextArea.vue';
import CommentButton from '@/components/general/CommentButton.vue';
import ErrorMessage from './ErrorMessage.vue';

export default {
    components: {
        StarsRating,
        CommentButton,
        TextArea,
        ErrorMessage,
    },
    props: {
        commentButtonLabel: {
            type: String,
            required: true,
        },
        value: {
            type: Number,
            default: 0,
        },
        comment: {
            type: String,
            default: ''
        },
        isExpanded: {
            type: Boolean,
            default: false,
        },
        commentPlaceholder: {
            type: String,
            default: ''
        },
        commentError: {
            type: String,
            default: null,
        },
        valueError: {
            type: String,
            default: null
        }
    },
    emits: [
        'update:comment',
        'update:value',
        'expand:comment'
    ],
}
</script>

<template>
<div class="rating-input">
    <div class="rating-input__star-container">
        <stars-rating :active="value" @update:active="$emit('update:value', $event)" />
        <error-message :text="valueError" />
    </div>
    <div class="rating-input__comment-container" v-if="value">
        <comment-button
            class="comment-button"
            :label="commentButtonLabel"
            v-if="!isExpanded"
            @click="$emit('expand:comment')" />
        <text-area
            class="rating-input__comment-input"
            :placeholder="commentPlaceholder"
            v-if="isExpanded"
            :model-value="comment"
            :error-message="commentError"
            @update:model-value="$emit('update:comment', $event)" />
    </div>
</div>
</template>

<style>
.rating-input__star-container {
    position: relative;
}

.rating-input__comment-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 1rem;
}

.rating-input__comment-input {
    width: 100%;
}
</style>