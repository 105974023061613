<script>
export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
        language: {
            type: [String, null],
            default: null
        }
    },
    computed: {
        itemName() {
            const langName = this.item["name_" + this.language];
            if (langName) return langName;
            return this.item.name;
        },
        imageUrl() {
            return this.item.imageUrls && this.item.imageUrls[0];
        },
    }
}
</script>

<template>
    <div class="item-display">
        <div class="item-display__name">{{ itemName }}</div>
        <img v-if="imageUrl" class="item-display__image" :src="imageUrl" />
    </div>
</template>

<style>
.item-display {
    text-align: center;
    padding: 25px;
    background-color: white;
}

.item-display__name {
    font-size: 17px;
    padding-bottom: 20px;
}

.item-display__image {
    height: 155px;
    width: 122px;
    text-align: center;
    object-fit: cover;
}
</style>