<script>
import RatingInput from '../RatingInput.vue';

export default {
    components: {
        RatingInput
    },
    props: {
        label: {
            type: String,
            required: true,
        },
        modelValue: {
            type: Number,
            default: null
        },
        errorText: {
            type: String,
            default: null,
        },
        comment: {
            type: String,
            default: null
        },
        commentErrorText: {
            type: String,
            default: null
        },
        isExpanded: {
            type: Boolean,
            default: false,
        },
        commentPlaceholder: {
            type: [String, null],
            default: null,
        },
        commentButtonLabel: {
            type: String,
            default: "Add comment"
        }
    },
    emits: [
        'update:model-value',
        'update:comment',
        'expand:comment'
    ]
}
</script>

<template>
    <div class="rating-field">
        <h2>{{label}}</h2>
        <rating-input
            :value="modelValue"
            :comment="comment"
            @update:comment="$emit('update:comment', $event)"
            @update:value="$emit('update:model-value', $event)"
            :comment-error="commentErrorText"
            :value-error="errorText"
            :is-expanded="isExpanded"
            :commentPlaceholder="commentPlaceholder"
            @expand:comment="$emit('expand:comment')"
            :comment-button-label="commentButtonLabel"
            />
    </div>
</template>