<script>
import ErrorMessage from './ErrorMessage.vue';

export default {
    components: {
        ErrorMessage,
    },
    props: {
        modelValue: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: '',
        },
        max: {
            type: Number,
            default: 5000,
        },
        errorMessage: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            text: this.modelValue
        };
    },
    emits: ['update:model-value'],
    computed: {
        charCount() {
            if (!this.modelValue) return 0;
            return this.modelValue.length;
        }
    },
    watch: {
        text(str) {
            this.text = str.slice(0, this.max);
            this.$emit('update:model-value', this.text);
        },
        modelValue(str) {
            this.text = str;
        }
    },
}
</script>

<template>
    <div class="text-area">
        <textarea class="text-area__textarea" :placeholder="placeholder" v-model="text">
        </textarea>
        <div class="text-area__char-count">
            {{charCount}}/{{max}}
        </div>
        <error-message class="text-area__error" :text="errorMessage" />
    </div>
</template>

<style>
    .text-area {
        position: relative;
    }

    .text-area__textarea {
        border: 1px solid #E8E8E8;
        padding: 13px;
        width: calc(100% - 26px);
        min-height: 93px;
    }

    .text-area__textarea::placeholder {
        color: #878787;
    }

    .text-area__error {
        bottom: -4px;
    }

    .text-area__char-count {
        color: #CDCDCD;
        font-size: 12px;
        text-align: right;
    }
</style>