<script>
import TextArea from '../TextArea';

export default {
    components: {
        TextArea
    },
    props: {
        label: {
            type: String,
            required: true,
        },
        modelValue: {
            type: String,
            default: '',
        },
        errorMessage: {
            type: String,
            default: null
        }
    },
    emits: [
        'update:model-value'
    ]
}
</script>

<template>
    <div class="string-field">
        <h2>{{label}}</h2>
        <text-area
            class="comment-input"
            :model-value="modelValue"
            :error-message="errorMessage"
            @update:modelValue="$emit('update:model-value', $event)" />
    </div>
</template>