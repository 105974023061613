<script>
// eslint-disable-next-line
import { h } from 'vue'

const StarButton = (props) => {
    const color = props.active ? '#333333' : '#C2C3CB';

    return (
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.8644 0.618951C12.1006 -0.124819 13.1531 -0.124816 13.3893 0.618954L15.6482 7.73083C15.7537 8.06301 16.0621 8.28866 16.4106 8.28866H23.7585C24.529 8.28866 24.8541 9.27108 24.2357 9.73074L18.2601 14.172C17.9861 14.3757 17.8715 14.7309 17.9749 15.0563L20.2499 22.2191C20.485 22.9594 19.6336 23.5667 19.0102 23.1034L13.1041 18.7136C12.8208 18.5031 12.4329 18.5031 12.1496 18.7136L6.24346 23.1034C5.62008 23.5667 4.76865 22.9594 5.00377 22.2191L7.27881 15.0563C7.38217 14.7309 7.2676 14.3757 6.99356 14.172L1.01801 9.73074C0.39955 9.27108 0.724654 8.28866 1.49522 8.28866H8.84304C9.19158 8.28866 9.5 8.06301 9.60551 7.73083L11.8644 0.618951Z" fill={color}/>
      </svg>
    );
}

StarButton.props = {
    active: {
      type: Boolean,
      default: false,
    },
};

export default StarButton;
</script>
