<script>
export default {
    props: {
        label: {
            type: String,
            required: true
        }
    }
}
</script>

<template>
    <div class="comment-button">
        {{label}}
        <span class="comment-button__icon"></span>
    </div>
</template>

<style>
.comment-button {
    align-items: center;
    display: flex;
    font-size: 12px;
    letter-spacing: 1px;
    cursor: default;
    user-select: none;
    line-height: 24px;
}

.comment-button__icon {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    background-color: #333333;
    color: white;
    margin-left: 7px;
    position: relative;
}

.comment-button__icon::before,
.comment-button__icon::after {
    content: '';
    width: 2px;
    height: 14px;
    position: absolute;
    left: 11px;
    top: 5px;
    background-color: white;
}

.comment-button__icon::after {
    transform: rotate(90deg)
}
</style>