<script>
import CloseButton from '@/components/general/CloseButton.vue';

const maxFieldIndex = 999;

const ratingFieldSortOrder = [
    "websiteRating",
    "shippingRating",
    "packagingRating",
    "designRating",
    "productRating",
    "sizeRating",
    "fabricRating",
];

function ratingFieldSortFunction(a, b) {
    return indexOrMax(a) - indexOrMax(b);
}

function indexOrMax(name) {
    const indexResult = ratingFieldSortOrder.indexOf(name);
    if (indexResult === -1) return maxFieldIndex;
    return indexResult;
}

export default {
    components: {
        CloseButton,
    },
    inject: [
        'query',
        'tycka',
        'close'
    ],
    props: {
        labels: {
            type: Object,
            required: true,
        },
        tyckaData: {
            type: Object,
            required: true,
        },
        submittedData: {
            type: Object,
            required: true,
        }
    },
    computed: {
        sortedRatingFields() {
            var fieldsClone = this.tyckaData.item.fields.filter(f => f.type === "Rating");
            fieldsClone.sort(ratingFieldSortFunction);
            return fieldsClone;
        }
    },
    methods: {
        getRatingFor(rating) {
            const stars = this.submittedData[rating.name].value * 5;
            return this.labels.youRated.replace('{ratingStars}', `${stars}/5`);
        },
        getCommentFor(rating) {
            return this.submittedData[rating.name].comment;
        },
        getLabel(key) {
            return this.labels[key] || key;
        },
    }
}
</script>

<template>
    <div class="general-thank-you">
        <header class="general-thank-you__header">
            <h1>{{labels.thankYouTitle}}</h1>
            <p>{{labels.thankYouSubtitle}}</p>
            <close-button class="general-thank-you__close-button" @click="close" />
        </header>
        <div>
            <div v-for="ratingField in sortedRatingFields" :key="ratingField.name" class="general-thank-you__rating">
                <h2>{{ getLabel(ratingField.name) }}</h2>
                <p class="general-thank-you__stars-with-label"><span class="general-thank-you__stars">{{getRatingFor(ratingField)}}</span></p>
                <p class="general-thank-you__rating-comment" v-if="getCommentFor(ratingField)">{{getCommentFor(ratingField)}}</p>
            </div>
        </div>
        <a class="general-thank-you__button" href="#" @click="close">{{labels.backToStore}}</a>
    </div>
</template>

<style>
.general-thank-you__header {
    height: 110px;
    width: 100%;
    color: #333333;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.general-thank-you__close-button.close-button {
    position: absolute;
    right: 10px;
    top: 10px;
}

.general-thank-you__close-button.close-button {
    position: absolute;
    right: 10px;
    top: 10px;
}
.general-thank-you__rating {
    border-top: 1px solid #F7F7F7;
    padding: 23px 0;
}

.general-thank-you__rating:last-child {
    border-bottom: 1px solid #F7F7F7;
}

.general-thank-you__rating p {
    margin: 12px 0 0;
}

.general-thank-you__stars {
    font-size: 16px;
    font-weight: bold;
    color: #0A1130;
}

.general-thank-you__stars-with-label {
    font-size: 15px;
    color: #858897;
    line-height: 16px;
    text-align: center;
    margin-bottom: 10px;
}

.general-thank-you__rating-comment {
    text-align: center;
    margin-top: 10px;
    font-size: 14px;
}

.general-thank-you__close-button.close-button::before,
.general-thank-you__close-button.close-button::after {
    background-color: #333333;
}

.general-thank-you__rating h2 {
    text-align: center;
    font-size: 12px;
}

.general-thank-you__button {
    line-height: 40px;
    display: block;
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
    text-decoration: none;
    color: #333333;
    margin-top: 30px;
}
</style>