<script>
import GeneralForm from '@/components/general/GeneralForm.vue';
import GeneralThankYou from '@/components/general/GeneralThankYou.vue';

const defaultLabels = {
    addComment: "Add comment",
    formTitle: "Review",
    formSubtitle: "Tell us what you think",
    errorRequired: "Required",
    name: "Name",
    namePlaceholder: "Your name",
    sendReview: "Send review",
    thankYouTitle: "Thank you",
    thankYouSubtitle: "Your review was sent",
    youRated: "You rated {ratingStars}",
    backToStore: "Back to store",
}

export default {
    components: {
        GeneralForm,
        GeneralThankYou,
    },
    data() {
        return {
            tyckaData: null,
            submittedData: null,
        }
    },
    inject: [
        'query',
        'close',
    ],
    props: {
        config: {
            type: Object,
            required: true,
        }
    },
    computed: {
        labels() {
            return {
                ...defaultLabels,
                ...(this.config.labels || {})
            };
        },
        fieldOrder() {
            return this.config.fieldOrder || {};
        },
        customFieldMapOverrides() {
            return this.config.fieldDataMap || {};
        },
        optionalFields() {
            return this.config.optionalFields || [];
        },
    },
    methods: {
        setTyckaData(tyckaData) {
            if (!tyckaData || !tyckaData.transaction) {
                console.warn('Could not load transaction data from tycka, closing form.');
                this.close();
                return;
            }
            this.tyckaData = tyckaData;
        },
        setSubmittedData(submittedData) {
            this.submittedData = submittedData;
        }
    }
}
</script>

<template>
  <div class="general">
    <general-form
        v-if="!submittedData"
        :labels="labels"
        :language="config.language"
        :languageHint="config.languageHint"
        :field-order="fieldOrder"
        :custom-field-map-overrides="customFieldMapOverrides"
        :optional-fields="optionalFields"
        @submitted-data:updated="setSubmittedData"
        @tycka-data:updated="setTyckaData"
        />
    <general-thank-you
        v-if="submittedData"
        :labels="labels"
        :submitted-data="submittedData"
        :tycka-data="tyckaData"
    />
  </div>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
.general {
    height: 100%;
    font-family: 'Poppins', sans-serif;
    color: #121212;
}

h1 {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    margin: 8px;
}

h2 {
    font-size: 14px;
    margin: 0 0 12px;
    padding: 0;
    font-weight: normal;
}

h3 {
    font-size: 17px;
    font-weight: bold;
    color: #333333;
    max-width: 180px;
    margin: 26px auto 16px;
}

header p {
    text-align: center;
    font-size: 14px;
    margin: 0;
    letter-spacing: 1px;
}
</style>