<script>
import ErrorMessage from '../ErrorMessage.vue';

export default {
    components: {
        ErrorMessage,
    },
    props: {
        label: {
            type: String,
            required: true,
        },
        modelValue: {
            type: Number,
            default: null
        },
        errorMessage: {
            type: String,
            default: null
        },
    },
    emits: [
        'update:model-value'
    ]
}
</script>

<template>
    <div class="numeric-field">
        <h2>{{label}}</h2>
        <input
            class="numeric-field__input"
            type="number"
            :value="modelValue"
            @input="$emit('update:model-value', $event.target.value)"
            />
        <error-message class="numeric-field__error" :text="errorMessage" />
    </div>
</template>

<style>
.numeric-field__input {
    border: 1px solid #E8E8E8;
    font-size: 19px;
    width: 130px;
    margin: 0 auto;
    padding: 22px 15px;
}

.numeric-field__error {
    left: calc(50% - 81px);
    bottom: 5px;
}
</style>