<script>
export default {
    props: {
        text: {
            type: String,
            default: null
        }
    }
}
</script>

<template>
    <div
        class="error-message"
        v-if="text"
        >
        {{text}}
    </div>
</template>

<style>
.error-message {
    position: absolute;
    font-size: 14px;
    color: #F52214;
}
</style>